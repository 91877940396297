import type { TProduct } from '../../../product.types';

export const mockApi_Product_3: TProduct = {
  id: 'LearnProekt-product-id-3',
  sellerId: 'LearnProekt',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Գերմաներեն լեզվի դասընթացներ A2 առաջադեմ ուսանողների համար',
  companyName: 'LearnProekt',
  logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
  shortInfoText: 'Գերմաներենի դպրոց',
  fullInfoText:
    'Առցանց գրադարան A2 + «A2 մակարդակի նախապատրաստման տեսադասընթաց» Անվճար մասնակցություն շաբաթական զրույցի և քերականության խմբակներին՝ 8 շաբաթ։',
  pictures: ['/assets/sellers/LearnProekt/card-1-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 23000,
};
