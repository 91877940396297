import type { TProductListItem } from '../../../product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'shatskaya-product-id-1',
    sellerId: 'shatskaya',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'Fascial gymnastics',
    companyName: 'Shatskaya K. K.',
    logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
    pictureURL: '/assets/sellers/shatskaya/card-1-260x260.png',
    price: 4000,
  },
  {
    id: 'shatskaya-product-id-2',
    sellerId: 'shatskaya',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'A healthy back',
    companyName: 'Shatskaya K. K.',
    logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
    pictureURL: '/assets/sellers/shatskaya/card-2-260x260.png',
    price: 6000,
  },
  {
    id: 'shatskaya-product-id-3',
    sellerId: 'shatskaya',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'Mini "RESOURCE COURSE"',
    companyName: 'Shatskaya K. K.',
    logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
    pictureURL: '/assets/sellers/shatskaya/card-3-260x260.png',
    price: 9000,
  },
];
