import type { TSeller } from '../../../seller.types';

export const mockApi_seller: TSeller = {
  id: 'nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  bigBoard: '/assets/sellers/nethouse/logo-1440x200.png',
  companyName: 'Nethouse',
  shortInfoText: 'A full-fledged landing page builder',
  info: 'Create a website, online store or landing page based on templates or choose a ready-made option',
};
