import type { TProduct } from '../../../product.types';

export const mockApi_Product_3: TProduct = {
  id: 'LearnProekt-product-id-3',
  sellerId: 'LearnProekt',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'German language courses for continuing A2',
  companyName: 'LearnProekt',
  logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
  shortInfoText: 'German Language School',
  fullInfoText:
    'A2 + Online Library “A2 Level Preparation Video Course” Free participation in weekly speaking and grammar clubs 8 weeks',
  pictures: ['/assets/sellers/LearnProekt/card-1-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 23000,
};
