import ArCaLogoSVG from 'assets/arca-logo.svg';
import MaestroLogoSVG from 'assets/maestro-logo.svg';
import McLogoSVG from 'assets/mc-logo.svg';
import VisaLogoSVG from 'assets/visa-logo.svg';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { ROUTES } from 'constants/routes';

import { useLocale } from '../../hooks/useLocale';
import { Modal } from '../Modal';

import css from './footer.module.scss';

interface TProps {
  className: string;
}

export const Footer = ({ className }: TProps): JSX.Element => {
  const router = useRouter();
  const [locale] = useLocale();
  const isProductPage = router.pathname.includes(ROUTES.PRODUCT);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <footer
      className={cn(className, css.wrapper, isProductPage && css.productPage)}
    >
      <div className={css.links}>
        <a
          target="_blank"
          href={`${ROUTES.DOCS}/${locale}/terms-of-use`}
          rel="noreferrer"
        >
          <span>
            {locale === 'en' ? 'Terms of sale,' : 'Վաճառքի պայմանները'}
          </span>
        </a>
        <a
          target="_blank"
          href={`${ROUTES.DOCS}/${locale}/conditions-of-use`}
          rel="noreferrer"
        >
          <span>
            {locale === 'en' ? 'Conditions of Use,' : 'Օգտագործման պայմանները'}
          </span>{' '}
        </a>
        <a
          target="_blank"
          href={`${ROUTES.DOCS}/${locale}/policy`}
          rel="noreferrer"
        >
          <span>
            {locale === 'en'
              ? 'Privacy Policy,'
              : 'Գաղտնիության քաղաքականություն'}
          </span>{' '}
        </a>
        <span onClick={(): void => setIsModalOpen(true)}>
          {locale === 'en' ? 'About' : 'Մեր մասին'}
        </span>
      </div>
      <div className={css.icons}>
        <ArCaLogoSVG viewBox="0 0 216 60" className={css.svg} />
        <MaestroLogoSVG viewBox="0 0 48 30" className={css.svg} />
        <McLogoSVG viewBox="0 0 48 30" className={css.svg} />
        <VisaLogoSVG viewBox="0 0 48 30" className={css.svg} />
      </div>
      <Modal
        className={css.modal}
        isOpen={isModalOpen}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
      >
        {locale === 'en' ? (
          <div>
            <p>GG INVESTMENTS LLC</p>
            <p>Registration</p>
            <p>number: 286.110.1229416 / 2022-04-07, Tax ID: 02859123</p>
            <p>Address: office 367, 5A Vardanants Str., Yerevan, Armenia</p>
            <p>TIN: 02859123</p>
            <p>Email: partnership@gginvestments.am</p>
            <p>+374 98 599546</p>
          </div>
        ) : (
          <div>
            <p>«ՋԻ ՋԻ ԻՆՎԵՍՏՄԸՆԹՍ» ՍՊԸ</p>
            <p>Գրանցման համար' 286.110.1229416 / 2022-04-07</p>
            <p>ՀՎՀՀ' 02859123</p>
            <p>
              Գտնվելու վայրը' Հայաստան, Երեվան, Կենտրոն, Վարդանանց փ., 5 ա, բն.
              367 տարածք
            </p>
            <p>էլ. փոստ' partnership@gginvestments.am</p>
            <p>Հեռախոսահամար' +374 98 599546</p>
          </div>
        )}
      </Modal>
    </footer>
  );
};
