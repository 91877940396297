import { useLocale } from '../../../hooks/useLocale';

import css from './localeSwitcher.module.scss';

export function LocaleSwitcher(): JSX.Element {
  const [locale, toggleLocate] = useLocale();

  return (
    <ul className={css.wrapper}>
      <li
        onClick={(): void => {
          toggleLocate();
        }}
      >
        {locale === 'en' ? 'hy' : 'en'}
      </li>
    </ul>
  );
}
