import type { TSeller } from '../../../seller.types';

export const mockApi_seller: TSeller = {
  id: 'nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  bigBoard: '/assets/sellers/nethouse/logo-1440x200.png',
  companyName: 'Nethouse',
  shortInfoText: 'Լիարժեք լենդինգների կոնստրուկտոր',
  info: 'Կաղապարների հիման վրա ստեղծեք կայք, առցանց խանութ կամ լենդինգ կամ ընտրեք պատրաստի տարբերակ',
};
