import type { TProductListItem } from '../../../product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'NinetyNine-product-id-1',
    sellerId: 'NinetyNine',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'NUTRITION AND TRAINING Course',
    companyName: 'NinetyNine',
    logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
    pictureURL: '/assets/sellers/NinetyNine/card-1-260x260.png',
    price: 6000,
  },
  {
    id: 'NinetyNine-product-id-2',
    sellerId: 'NinetyNine',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'Intensive PELVIC FLOOR MUSCLES',
    companyName: 'NinetyNine',
    logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
    pictureURL: '/assets/sellers/NinetyNine/card-2-260x260.png',
    price: 2000,
  },
];
