import type {
  TProductListResponse,
  TProductResponse,
} from '../../../product.types';
import type { TSellerResponse } from '../../../seller.types';
import { mockApi_Product_1 } from './product_1';
import { mockApi_Product_2 } from './product_2';
import { mockApi_productList } from './product_list';
import { mockApi_seller } from './seller';

export const productList: TProductListResponse = {
  code: 200,
  data: mockApi_productList,
};

export const product_1: TProductResponse = {
  code: 200,
  data: mockApi_Product_1,
};

export const product_2: TProductResponse = {
  code: 200,
  data: mockApi_Product_2,
};

export const seller_info: TSellerResponse = {
  code: 200,
  data: mockApi_seller,
};
