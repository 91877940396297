/* eslint-disable */
import cn from 'classnames';
import type { ReactNode, MouseEvent, RefObject } from 'react';
import React, { useState } from 'react';

// import { toast } from 'react-toastify';
import Loader from '../Loader';

import css from './button.module.scss';

interface IProps {
  children: ReactNode;
  theme?:
    | 'positive'
    | 'negative'
    | 'warning'
    | 'invisibleBlue'
    | 'light'
    | 'white';
  onClick?: (data: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  url?: string;
  fileName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  innerRef?: RefObject<HTMLButtonElement>;
  'data-test-id'?: string;
}

const handleDownload =
  (
    url: string,
    fileName: string,
    setDownloadLoading: (data: boolean) => void,
  ) =>
  () => {
    setDownloadLoading(true);
    // const xhr = new XMLHttpRequest();
    // xhr.open('GET', url, true);
    // xhr.responseType = 'blob';
    //
    // xhr.onload = (e: ProgressEvent<XMLHttpRequest>) => {
    //     const target = e.target as XMLHttpRequest;
    //     if (target.status === 200) {
    //         const blob = new Blob([target.response], { type: 'application/vnd.ms-excel' });
    //         const link = document.createElement('a');
    //         link.href = window.URL.createObjectURL(blob);
    //         link.download =
    //             fileName ||
    //             `Выгрузка правил_${new Date()
    //                 .toLocaleString()
    //                 .replace(/[.]|[,]|[ ]|[:]/g, '_')}.xlsx`;
    //         link.click();
    //     } else {
    //         toast.error('Упс! Ошибка при скачивании.');
    //     }
    //     setDownloadLoading(false);
    // };
    // xhr.send();
  };

const getThemeStyle = (theme: string): string => {
  switch (theme) {
    case 'warning': {
      return css.warningActive;
    }

    case 'negative': {
      return css.negativeActive;
    }

    case 'light': {
      return css.lightActive;
    }

    case 'white': {
      return css.whiteActive;
    }

    default: {
      return css.positiveActive;
    }
  }
};

export function Button(props: IProps) {
  const {
    className,
    children,
    onClick,
    disabled,
    isLoading,
    theme = 'positive',
    innerRef,
    url,
    fileName,
  } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const onDownloadClick = handleDownload(
    url as string,
    fileName as string,
    setDownloadLoading,
  );

  return (
    <button
      ref={innerRef}
      type="button"
      className={cn(
        css.button,
        className,
        !disabled ? getThemeStyle(theme) : '',
      )}
      onClick={url ? onDownloadClick : onClick}
      disabled={disabled || isLoading || downloadLoading}
    >
      <div className={cn(css.content, isLoading && css.contentLoading)}>
        {children}
        {isLoading || downloadLoading ? (
          <Loader className={css.loader} />
        ) : null}
      </div>
    </button>
  );
}
