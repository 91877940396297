import type { TProduct } from '../../../product.types';

export const mockApi_Product_1: TProduct = {
  id: 'SkillFactory-product-id-1',
  sellerId: 'SkillFactory',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Տվյալների վերլուծաբանի դասընթաց',
  companyName: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  shortInfoText: 'Առցանց դասընթացներ',
  fullInfoText:
    'Էլեկտրոնային առևտրի (էլեկտրոնային առևտուր) և GameDev (խաղերի մշակում) բիզնես մոդելների ներածություն։ Դուք ոչ միայն կանդրադառնաք տեսությանը, այլև կաշխատեք Google Sheets-ի հետ, կսովորեք վիճակագրության, SQL-ի և Python-ի հիմունքները՝ տվյալների արդյունավետ վերլուծության համար:',
  variants: [],
  pictures: ['/assets/sellers/SkillFactory/card-1-590x590.png'],
  beforePrice: null,
  price: 350000,
};
