import type { TProduct } from '../../../product.types';

export const mockApi_Product_3: TProduct = {
  id: 'SkillFactory-product-id-3',
  sellerId: 'SkillFactory',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Python Developer Course',
  companyName: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  shortInfoText: 'Online school',
  fullInfoText:
    'Become a Python developer — master asynchronous programming and after 3 months of training become a successful candidate when looking for a job',
  pictures: ['/assets/sellers/SkillFactory/card-3-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 350000,
};
