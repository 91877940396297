import type { TSeller } from '../../../seller.types';

export const mockApi_seller: TSeller = {
  id: 'LearnProekt',
  logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
  bigBoard: '/assets/sellers/LearnProekt/logo-1440x200.png',
  companyName: 'LearnProekt',
  shortInfoText: 'German Language School',
  info: 'Learn the language at the largest online school in Europe',
};
