import type { TProduct } from '../../../product.types';

export const mockApi_Product_1: TProduct = {
  id: 'LearnProekt-product-id-1',
  sellerId: 'LearnProekt',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'German language courses from scratch',
  companyName: 'LearnProekt',
  logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
  shortInfoText: 'German Language School',
  fullInfoText:
    'A1 + Online library "Introductory course in German for beginners" Free participation in weekly speaking and grammar clubs 8 weeks',
  variants: [],
  pictures: ['/assets/sellers/LearnProekt/card-1-590x590.png'],
  beforePrice: null,
  price: 23000,
};
