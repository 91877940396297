import type { TSeller } from '../../../seller.types';

export const mockApi_seller: TSeller = {
  id: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  bigBoard: '/assets/sellers/SkillFactory/logo-1440x200.png',
  companyName: 'SkillFactory',
  shortInfoText: 'Online school',
  info: 'Learn how to work in IT',
};
