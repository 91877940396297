import { useRouter } from 'next/router';

export function useLocale(): [string, () => void] {
  const router = useRouter();
  const locale =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('locale') || 'hy'
      : 'hy';

  const handleToggleLocale = (): void => {
    const value = locale === 'en' ? 'hy' : 'en';
    if (typeof window !== 'undefined')
      window.localStorage.setItem('locale', value);
    router.reload();
  };

  return [locale, handleToggleLocale];
}
