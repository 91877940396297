import type { TProduct } from '../../../product.types';

export const mockApi_Product_2: TProduct = {
  id: 'shatskaya-product-id-2',
  sellerId: 'shatskaya',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'A healthy back',
  companyName: 'Shatskaya K. K.',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  shortInfoText: 'Consultations on yoga therapy and yoga',
  fullInfoText: 'Exercises for a healthy back',
  pictures: ['/assets/sellers/shatskaya/card-2-590x590.png'],
  price: 6000,
  beforePrice: null,
  variants: [],
};
