import type { TProductListItem } from '../../../product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'Sychev-product-id-1',
    sellerId: 'Sychev',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Առցանց խորհրդատվություն',
    companyName: 'Sychev K. I.',
    logoURL: '/assets/sellers/Sychev/logo-88x88.png',
    pictureURL: '/assets/sellers/Sychev/card-1-260x260.png',
    price: 4000,
  },
  {
    id: 'Sychev-product-id-2',
    sellerId: 'Sychev',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Առցանց խորհրդատվություն',
    companyName: 'Sychev K. I.',
    logoURL: '/assets/sellers/Sychev/logo-88x88.png',
    pictureURL: '/assets/sellers/Sychev/card-2-260x260.png',
    price: 6000,
  },
  {
    id: 'Sychev-product-id-3',
    sellerId: 'Sychev',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Առցանց խորհրդատվություն',
    companyName: 'Sychev K. I.',
    logoURL: '/assets/sellers/Sychev/logo-88x88.png',
    pictureURL: '/assets/sellers/Sychev/card-3-260x260.png',
    price: 3000,
  },
];
