import type { TProduct } from '../../../product.types';

export const mockApi_Product_2: TProduct = {
  id: 'nethouse-product-id-2',
  sellerId: 'nethouse',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'The "Store" tariff',
  companyName: 'Nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  shortInfoText:
    'The designer of websites, online stores and landing pages. Three tariff plans to choose from.',
  fullInfoText:
    'For online stores. 1000+ products, unlimited number of images, catalog import/export, promo codes, personal account of clients, separation of access rights, SMS notifications to clients. The price is for one month.',
  variants: [],
  pictures: ['/assets/sellers/nethouse/card-2-590x590.png'],
  price: 1050,
  beforePrice: 1780,
};
