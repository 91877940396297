export enum STORE {
  ORDER = 'order',
  QR_CODE = 'qr-code',
  PRODUCT_LIST = 'product-list',
  EXCHANGE_RATE = 'exchange-rate',
  PRODUCT = 'product',
  SELLER = 'seller',
  FILTER = 'filter',
}

export interface TStoreFilter {
  searchValue: string;
  category: string | null;
}

export const DEFAULT_FILTER: TStoreFilter = {
  searchValue: '',
  category: null,
};
