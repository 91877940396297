import { useQueryClient } from '@tanstack/react-query';
import ArmmpLogoSVG from 'assets/armmp-logo.svg';
import BurgerSVG from 'assets/burger.svg';
import cn from 'classnames';
import { useRouter } from 'next/router';
import type { MouseEvent } from 'react';
import React, { useState } from 'react';

import { ROUTES } from 'constants/routes';
import type { TStoreFilter } from 'constants/store';
import { STORE } from 'constants/store';

import Button from 'components/Button';
import Input from 'components/Input';
import Menu from 'components/Menu';

import { useLocale } from '../../hooks/useLocale';
import LocaleSwitcher from './components';

import css from './header.module.scss';

const invisiblePathNames = [
  ROUTES.ERRORS,
  ROUTES.PAYMENT,
  ROUTES.ORDER,
  '/404',
  '/500',
];
interface TProps {
  className: string;
}

export const Header = ({ className }: TProps): JSX.Element => {
  const router = useRouter();
  const [locale] = useLocale();
  const queryClient = useQueryClient();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  // const filter = queryClient.getQueryData([STORE.FILTER]);

  const handleToggleMenu = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubmit = (value: string): void => {
    setInputValue(value);
    queryClient.setQueryData([STORE.FILTER], (prevState?: TStoreFilter) => ({
      ...prevState,
      searchValue: value,
      category: null,
    }));
  };

  const handleCategorySelect = (category: string | null): void => {
    setInputValue('');
    queryClient.setQueryData([STORE.FILTER], (prevState?: TStoreFilter) => ({
      ...prevState,
      searchValue: '',
      category,
    }));
  };

  if (invisiblePathNames.some(pathName => pathName === router.pathname)) {
    return null;
  }

  return (
    <>
      <header className={cn(className, css.header)}>
        <div className={css.wrapper}>
          <div
            className={css.logoWrapper}
            onClick={(): void => {
              router.push(ROUTES.ROOT);
            }}
          >
            <ArmmpLogoSVG className={css.forsomLogo} />
          </div>
          <Button
            theme="light"
            className={css.burgerButton}
            onClick={handleToggleMenu}
          >
            <BurgerSVG viewBox="0 0 24 17" className={css.burgerIcon} />
          </Button>
          <Input
            id="forsom-search-input"
            placeholder={
              locale === 'en'
                ? 'Search for products or services'
                : 'Ապրանքների կամ ծառայությունների որոնում'
            }
            className={css.input}
            value={inputValue}
            onClear={(): void => handleSubmit('')}
            onChange={({ target }): void => setInputValue(target.value)}
            onEnter={(): void => handleSubmit(inputValue)}
          />
          <Button
            theme="light"
            className={css.button}
            onClick={(): void => handleSubmit(inputValue)}
          >
            {locale === 'en' ? 'Search' : 'որոնում'}
          </Button>
          <LocaleSwitcher />
        </div>
      </header>
      <Menu
        isMenuOpen={isMenuOpen}
        onClose={(): void => setIsMenuOpen(false)}
        onClickCategory={handleCategorySelect}
      />
    </>
  );
};
