export enum ROUTES {
  ROOT = '/',
  BUY = '/buy',
  ORDER = '/order',
  PAYMENT = '/payment',
  ERRORS = '/errors',
  PRODUCT = '/product',
  SELLER = '/seller',
  DOCS = '/docs',
  CHECK_STATUS = '/check-status',
  STATUS = '/status',
  HEALTZ = '/healtz',
}
