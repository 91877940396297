import type { QueryFunction } from '@tanstack/react-query';
import axios from 'axios';

import { API_URL } from '../constants/urls';
import type { TExchangeRate } from './exchange-rate.types';
import type { TProduct, TProductListItem } from './index';
import type { TSeller } from './seller.types';
// locale "en"
import {
  productList as en_learnProektProductList,
  product_1 as en_learnProektProduct1,
  product_2 as en_learnProektProduct2,
  product_3 as en_learnProektProduct3,
  seller_info as en_learnProektInfo,
} from './seller/en/LearnProekt';
import {
  productList as en_ninetyNineProductList,
  product_1 as en_ninetyNineProduct1,
  product_2 as en_ninetyNineProduct2,
  seller_info as en_ninetyNineInfo,
} from './seller/en/NinetyNine';
import {
  productList as en_skillFactoryProductList,
  product_1 as en_skillFactoryProduct1,
  product_2 as en_skillFactoryProduct2,
  product_3 as en_skillFactoryProduct3,
  seller_info as en_skillFactoryInfo,
} from './seller/en/SkillFactory';
import {
  productList as en_sychevProductList,
  product_1 as en_sychevProduct1,
  product_2 as en_sychevProduct2,
  product_3 as en_sychevProduct3,
  seller_info as en_sychevInfo,
} from './seller/en/Sychev';
import {
  productList as en_nethouseProductList,
  product_1 as en_nethouseProduct1,
  product_2 as en_nethouseProduct2,
  product_3 as en_nethouseProduct3,
  seller_info as en_nethouseInfo,
} from './seller/en/nethouse';
import {
  productList as en_shatskayaProductList,
  product_1 as en_shatskayaProduct1,
  product_2 as en_shatskayaProduct2,
  product_3 as en_shatskayaProduct3,
  seller_info as en_shatskayaInfo,
} from './seller/en/shatskaya';
// locale "hy" - default
import {
  productList as learnProektProductList,
  product_1 as learnProektProduct1,
  product_2 as learnProektProduct2,
  product_3 as learnProektProduct3,
  seller_info as learnProektInfo,
} from './seller/hy/LearnProekt';
import {
  productList as ninetyNineProductList,
  product_1 as ninetyNineProduct1,
  product_2 as ninetyNineProduct2,
  seller_info as ninetyNineInfo,
} from './seller/hy/NinetyNine';
import {
  productList as skillFactoryProductList,
  product_1 as skillFactoryProduct1,
  product_2 as skillFactoryProduct2,
  product_3 as skillFactoryProduct3,
  seller_info as skillFactoryInfo,
} from './seller/hy/SkillFactory';
import {
  productList as sychevProductList,
  product_1 as sychevProduct1,
  product_2 as sychevProduct2,
  product_3 as sychevProduct3,
  seller_info as sychevInfo,
} from './seller/hy/Sychev';
import {
  productList as nethouseProductList,
  product_1 as nethouseProduct1,
  product_2 as nethouseProduct2,
  product_3 as nethouseProduct3,
  seller_info as nethouseInfo,
} from './seller/hy/nethouse';
import {
  productList as shatskayaProductList,
  product_1 as shatskayaProduct1,
  product_2 as shatskayaProduct2,
  product_3 as shatskayaProduct3,
  seller_info as shatskayaInfo,
} from './seller/hy/shatskaya';

const MOCK_HY_EXCHANGE_RATE = 4; // 0.25
const convertList = (
  list: TProductListItem[],
  exchangeRate: unknown,
): TProductListItem[] =>
  list.map(item => ({
    ...item,
    price: +(item.price / +exchangeRate).toFixed(2),
    costInRubles: item.price,
    costInDollars: +(item.price / +exchangeRate).toFixed(2),
    exchangeRate: +exchangeRate,
  }));
const productsMap: Record<string, Record<string, TProduct>> = {
  hy: {
    'nethouse-product-id-1': nethouseProduct1.data,
    'nethouse-product-id-2': nethouseProduct2.data,
    'nethouse-product-id-3': nethouseProduct3.data,
    'shatskaya-product-id-1': shatskayaProduct1.data,
    'shatskaya-product-id-2': shatskayaProduct2.data,
    'shatskaya-product-id-3': shatskayaProduct3.data,
    'LearnProekt-product-id-1': learnProektProduct1.data,
    'LearnProekt-product-id-2': learnProektProduct2.data,
    'LearnProekt-product-id-3': learnProektProduct3.data,
    'NinetyNine-product-id-1': ninetyNineProduct1.data,
    'NinetyNine-product-id-2': ninetyNineProduct2.data,
    'SkillFactory-product-id-1': skillFactoryProduct1.data,
    'SkillFactory-product-id-2': skillFactoryProduct2.data,
    'SkillFactory-product-id-3': skillFactoryProduct3.data,
    'Sychev-product-id-1': sychevProduct1.data,
    'Sychev-product-id-2': sychevProduct2.data,
    'Sychev-product-id-3': sychevProduct3.data,
  },
  en: {
    'nethouse-product-id-1': en_nethouseProduct1.data,
    'nethouse-product-id-2': en_nethouseProduct2.data,
    'nethouse-product-id-3': en_nethouseProduct3.data,
    'shatskaya-product-id-1': en_shatskayaProduct1.data,
    'shatskaya-product-id-2': en_shatskayaProduct2.data,
    'shatskaya-product-id-3': en_shatskayaProduct3.data,
    'LearnProekt-product-id-1': en_learnProektProduct1.data,
    'LearnProekt-product-id-2': en_learnProektProduct2.data,
    'LearnProekt-product-id-3': en_learnProektProduct3.data,
    'NinetyNine-product-id-1': en_ninetyNineProduct1.data,
    'NinetyNine-product-id-2': en_ninetyNineProduct2.data,
    'SkillFactory-product-id-1': en_skillFactoryProduct1.data,
    'SkillFactory-product-id-2': en_skillFactoryProduct2.data,
    'SkillFactory-product-id-3': en_skillFactoryProduct3.data,
    'Sychev-product-id-1': en_sychevProduct1.data,
    'Sychev-product-id-2': en_sychevProduct2.data,
    'Sychev-product-id-3': en_sychevProduct3.data,
  },
};
const sellersMap: Record<string, Record<string, TSeller>> = {
  hy: {
    nethouse: nethouseInfo.data,
    shatskaya: shatskayaInfo.data,
    LearnProekt: learnProektInfo.data,
    NinetyNine: ninetyNineInfo.data,
    SkillFactory: skillFactoryInfo.data,
    Sychev: sychevInfo.data,
  },
  en: {
    nethouse: en_nethouseInfo.data,
    shatskaya: en_shatskayaInfo.data,
    LearnProekt: en_learnProektInfo.data,
    NinetyNine: en_ninetyNineInfo.data,
    SkillFactory: en_skillFactoryInfo.data,
    Sychev: en_sychevInfo.data,
  },
};
const sellersProductsMap: Record<string, Record<string, TProductListItem[]>> = {
  hy: {
    nethouse: nethouseProductList.data,
    shatskaya: shatskayaProductList.data,
    LearnProekt: learnProektProductList.data,
    NinetyNine: ninetyNineProductList.data,
    SkillFactory: skillFactoryProductList.data,
    Sychev: sychevProductList.data,
  },
  en: {
    nethouse: en_nethouseProductList.data,
    shatskaya: en_shatskayaProductList.data,
    LearnProekt: en_learnProektProductList.data,
    NinetyNine: en_ninetyNineProductList.data,
    SkillFactory: en_skillFactoryProductList.data,
    Sychev: en_sychevProductList.data,
  },
};

export const getExchangeRate: QueryFunction<
  TExchangeRate | null
> = async () => {
  const { data } = await axios(API_URL.EXCHANGE_RATE_URL);

  return data.data || null;
};

export const getProductList: QueryFunction<TProductListItem[]> = async ({
  queryKey,
}) => {
  const [, exchangeRate, locale] = queryKey as string[];
  const productList = Object.keys(sellersProductsMap[locale]).reduce(
    (accum, objectKey) => {
      const responseData = sellersProductsMap[locale][objectKey];
      const exchangeRateValue =
        locale === 'en' ? exchangeRate : MOCK_HY_EXCHANGE_RATE;

      return [...accum, ...convertList(responseData, exchangeRateValue)];
    },
    [],
  );

  return Promise.resolve(productList);
};

export const getSellerProductList: QueryFunction<
  TProductListItem[],
  string[]
> = async ({ queryKey }) => {
  const [, sellerId, exchangeRate, locale] = queryKey as string[];
  const productList: TProductListItem[] = sellersProductsMap[locale][sellerId];
  const exchangeRateValue =
    locale === 'en' ? exchangeRate : MOCK_HY_EXCHANGE_RATE;

  if (productList) {
    const result = convertList(productList, exchangeRateValue);

    return Promise.resolve(result);
  }

  return Promise.reject();
};

export const getProduct: QueryFunction<TProduct, string[]> = async ({
  queryKey,
}) => {
  const [, productId, exchangeRate, locale] = queryKey as string[];
  const productData: TProduct = productsMap[locale][productId];
  const exchangeRateValue =
    locale === 'en' ? exchangeRate : MOCK_HY_EXCHANGE_RATE;

  return productData
    ? Promise.resolve({
        ...productData,
        price: +(productData.price / +exchangeRateValue).toFixed(2),
        costInRubles: productData.price,
        costInDollars: +(productData.price / +exchangeRateValue).toFixed(2),
        exchangeRate: +exchangeRateValue,
        beforePrice: +(productData.beforePrice / +exchangeRateValue).toFixed(2),
      })
    : Promise.reject();
};

export const getSeller: QueryFunction<TSeller, string[]> = async ({
  queryKey,
}) => {
  const [, sellerId, locale] = queryKey as string[];
  const seller = sellersMap[locale][sellerId];

  if (seller) {
    return Promise.resolve(seller);
  }
  //    const { data } = await axios<TProductResponse>(`${FENIX_URL}/product/${productId}`);
  //  return data?.data || null;

  return Promise.reject();
};
