import type { TSeller } from '../../../seller.types';

export const mockApi_seller: TSeller = {
  id: 'NinetyNine',
  logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
  bigBoard: '/assets/sellers/NinetyNine/logo-1440x200.png',
  companyName: 'NinetyNine',
  shortInfoText: 'Առցանց ուսուցում և հեղինակային շրջագայություններ',
  info: 'Առցանց թրեյնինգ, որը կօգնի ձեզ ստեղծել ձեր երազանքների մարմինը և հեղինակային շրջագայություններ, որոնք կպարգևեն ձեզ անմոռանալի հույզեր',
};
