import type { TSeller } from '../../../seller.types';

export const mockApi_seller: TSeller = {
  id: 'Sychev',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  bigBoard: '/assets/sellers/Sychev/logo-1440x200.png',
  companyName: 'Sychev K. I.',
  shortInfoText: 'Online psychological consultation',
  info: 'Consultation with a psychologist at the Neformat clinic',
};
