import type { TProduct } from '../../../product.types';

export const mockApi_Product_1: TProduct = {
  id: 'NinetyNine-product-id-1',
  sellerId: 'NinetyNine',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Դասընթաց «ՍՆՆԴԻՐ ԵՎ ՈՒՍՈՒՑՈՒՄ»',
  companyName: 'NinetyNine',
  logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
  shortInfoText: 'Առցանց ուսուցում և հեղինակային շրջագայություններ',
  fullInfoText: '4 շաբաթ ուսուցում + 2 շաբաթ լրացուցիչ մուտք և',
  pictures: ['/assets/sellers/NinetyNine/card-1-590x590.png'],
  variants: [],
  beforePrice: null,
  price: 6000,
};
