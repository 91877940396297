import CloseIcon from 'assets/close.svg';
import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { LAYOUT_ID } from '../layouts/Page';

import css from './modal.module.scss';

interface TProps {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
  ['data-test-id']?: string;
}

export const DATA_TEST_ID = 'modal';

export function Modal(props: TProps): JSX.Element {
  const {
    className,
    children,
    isOpen,
    onClose,
    'data-test-id': dataTestId,
  } = props;

  useEffect(() => {
    const wrapperNode: HTMLElement | null = document.getElementById(LAYOUT_ID);

    if (isOpen && wrapperNode) {
      wrapperNode.style.filter = 'blur(5px)';
      wrapperNode.style.pointerEvents = 'none';
      wrapperNode.style.overflow = 'hidden';
      wrapperNode.style.zIndex = '-1';
    }

    if (!isOpen && wrapperNode) {
      wrapperNode.style.filter = '';
      wrapperNode.style.overflow = 'unset';
      wrapperNode.style.pointerEvents = 'unset';
      wrapperNode.style.zIndex = 'unset';
    }
  }, [isOpen]);

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' && onClose) onClose();
  };

  useEffect(() => {
    if (isOpen && onClose) document.addEventListener('keydown', handleKeyDown);
    if (!isOpen && onClose)
      document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  useEffect(() => {
    return () => {
      const wrapperNode: HTMLElement | null =
        document.getElementById(LAYOUT_ID);

      if (wrapperNode) {
        wrapperNode.style.filter = '';
        wrapperNode.style.overflow = 'unset';
        wrapperNode.style.pointerEvents = 'unset';
        wrapperNode.style.zIndex = 'unset';
      }
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const testId = dataTestId || DATA_TEST_ID;
  const ModalContent = (
    <div data-test-id={testId} className={cn(className, css.wrapper)}>
      {onClose && <CloseIcon className={css.close} onClick={onClose} />}
      {children}
    </div>
  );

  return isOpen ? createPortal(ModalContent, document.body) : null;
}
