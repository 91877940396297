import type { TProduct } from '../../../product.types';

export const mockApi_Product_3: TProduct = {
  id: 'Sychev-product-id-3',
  sellerId: 'Sychev',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Online consultation',
  companyName: 'Sychev K. I.',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  shortInfoText: 'Online psychological consultation',
  fullInfoText:
    'Session duration - 55 minutes Work experience: 3 years It works in the following approaches: AST Only works with adults. Does not work with pairs and dependencies',
  pictures: ['/assets/sellers/Sychev/card-3-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 3000,
};
