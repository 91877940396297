/* eslint-disable */
import CloseIcon from 'assets/close.svg';
import cn from 'classnames';
import type { CSSProperties, KeyboardEvent, ReactNode, RefObject } from 'react';
import React from 'react';

import Loader from 'components/Loader';

// import './_variables.css';
import css from './input.module.scss';

export interface TInputProps {
  id: string;
  value?: string | number | null;
  label?: string;
  type?: 'number' | 'password' | 'text';
  step?: string;
  min?: string;
  max?: string;
  helperText?: string;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: boolean;
  isLoading?: boolean;
  Icon?: ReactNode;
  style?: CSSProperties;
  inputRef?: RefObject<HTMLInputElement>;
  'data-test-id'?: string;
  validationFn?: (value: string | number) => boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (data: never) => void;
  onBlur?: (data: never) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClear?: (event: React.MouseEvent<HTMLOrSVGElement>) => void;
  onIconClick?: (data: never) => void;
}

export function Input(props: TInputProps) {
  const {
    id = String(Math.random()),
    helperText,
    type,
    step,
    min,
    max,
    className,
    style,
    value,
    label,
    Icon,
    inputRef,
    required,
    disabled,
    placeholder,
    autoComplete = false,
    isLoading,
    validationFn = () => true,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClear,
    onEnter,
    onIconClick,
    maxLength,
    'data-test-id': dataTestId,
  } = props;
  const testId = dataTestId || `testId-${id}`;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnter) onEnter(event);
    if (onKeyDown) onKeyDown(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      type === 'number' ? event.target.valueAsNumber : event.target.value;

    if (validationFn(value)) onChange(event);
  };

  return (
    <div
      data-test-id={`${testId}-wrapper`}
      style={style}
      className={cn(css.wrapper, disabled && css.wrapperDisabled, className)}
    >
      {label && (
        <label title={label} htmlFor={id}>
          <div className={cn(css.label, disabled && css.labelDisabled)}>
            <span>{label}</span>
            {required && <span className={css.required}>*</span>}
          </div>
        </label>
      )}
      <div
        className={cn(
          css.buttonsContainer,
          helperText === undefined && css.buttonsContainerWithoutHelperText,
        )}
      >
        <input
          ref={inputRef}
          id={id}
          type={type}
          step={step}
          maxLength={maxLength}
          autoComplete={autoComplete ? 'on' : 'off'}
          data-test-id={testId}
          min={min}
          max={max}
          className={cn(
            css.input,
            helperText && css.error,
            Icon || onClear ? css.withIcon : undefined,
            Icon && onClear ? css.withDoubleIcons : undefined,
          )}
          disabled={disabled}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
        />
        {Icon || onClear ? (
          <div className={css.buttons}>
            {Icon && (
              <div
                onClick={onIconClick}
                className={cn(
                  css.icon,
                  css.iconColor,
                  onIconClick && css.cursorPointer,
                )}
              >
                {Icon}
              </div>
            )}
            {onClear ? (
              <CloseIcon
                className={cn(
                  css.clearButton,
                  (value === '' ||
                    value === undefined ||
                    value === null ||
                    disabled) &&
                    css.inactiveClearButton,
                )}
                onClick={
                  value === '' || value === undefined || value === null
                    ? undefined
                    : onClear
                }
              />
            ) : null}
          </div>
        ) : null}
        {isLoading && (
          <div className={css.loader}>
            <Loader />
          </div>
        )}
      </div>
      {helperText ? (
        <div
          data-test-id="helperText"
          title={helperText}
          className={css.helperText}
        >
          <span>{helperText}</span>
        </div>
      ) : null}
    </div>
  );
}
