import type { TProduct } from '../../../product.types';

export const mockApi_Product_1: TProduct = {
  id: 'Sychev-product-id-1',
  sellerId: 'Sychev',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Online consultation',
  companyName: 'Sychev K. I.',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  shortInfoText: 'Online psychological consultation',
  fullInfoText:
    'The session duration is 60 minutes Work experience as a psychologist - 4 years Works in approaches: CBT and AST Only works with adults. Does not work:with couples and with addictions',
  variants: [],
  pictures: ['/assets/sellers/Sychev/card-1-590x590.png'],
  beforePrice: null,
  price: 4000,
};
