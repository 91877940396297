import type { TProduct } from '../../../product.types';

export const mockApi_Product_1: TProduct = {
  id: 'nethouse-product-id-1',
  sellerId: 'nethouse',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Սակագնային «Կայք»',
  companyName: 'Nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  shortInfoText: 'Լիարժեք լենդինգների կոնստրուկտոր',
  fullInfoText:
    'Այցեքարտերի կայքերի և վայրէջքների համար: Մինչև 10 ապրանք, մինչև 400 պատկեր: Վիճակագրություն, վճարումների ընդունում, ծանուցումներ Telegram-ում։',
  variants: [],
  pictures: ['/assets/sellers/nethouse/card-1-590x590.png'],
  price: 500,
  beforePrice: 1350,
};
