import type { TProductListItem } from '../../../product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'SkillFactory-product-id-1',
    sellerId: 'SkillFactory',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Տվյալների վերլուծաբանի դասընթաց',
    companyName: 'SkillFactory',
    logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
    pictureURL: '/assets/sellers/SkillFactory/card-1-260x260.png',
    price: 350000,
  },
  {
    id: 'SkillFactory-product-id-2',
    sellerId: 'SkillFactory',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Թեստավորման դասընթացներ',
    companyName: 'SkillFactory',
    logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
    pictureURL: '/assets/sellers/SkillFactory/card-2-260x260.png',
    price: 320000,
  },
  {
    id: 'SkillFactory-product-id-3',
    sellerId: 'SkillFactory',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Python մշակողների դասընթաց',
    companyName: 'SkillFactory',
    logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
    pictureURL: '/assets/sellers/SkillFactory/card-3-260x260.png',
    price: 350000,
  },
];
