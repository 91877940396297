import type { TProduct } from '../../../product.types';

export const mockApi_Product_1: TProduct = {
  id: 'Sychev-product-id-1',
  sellerId: 'Sychev',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Առցանց խորհրդատվություն',
  companyName: 'Sychev K. I.',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  shortInfoText: 'Առցանց խորհրդատվություն հոգեբանի հետ',
  fullInfoText:
    'Նիստի տևողությունը՝ 60 րոպե Աշխատանքային փորձ 4 տարի Աշխատում է մոտեցումներով՝ CBT և ACT Ում հետ է այն աշխատում՝ մեծահասակների հետ Չի աշխատում՝ զույգերով, կախվածությամբ',
  variants: [],
  pictures: ['/assets/sellers/Sychev/card-1-590x590.png'],
  beforePrice: null,
  price: 4000,
};
