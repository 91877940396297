import type { TProduct } from '../../../product.types';

export const mockApi_Product_2: TProduct = {
  id: 'Sychev-product-id-1',
  sellerId: 'Sychev',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Առցանց խորհրդատվություն',
  companyName: 'Sychev K. I.',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  shortInfoText: 'Առցանց խորհրդատվություն հոգեբանի հետ',
  fullInfoText:
    'Նիստի տևողությունը՝ 50 րոպե Աշխատանքային փորձ 6 տարի Աշխատում է մոտեցումներով՝ CBT, ACT, CFT Ում հետ է այն աշխատում՝ մեծահասակների հետ  Չի աշխատում՝ զույգերով, կախվածությամբ',
  pictures: ['/assets/sellers/Sychev/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 6000,
};
