import type { TProduct } from '../../../product.types';

export const mockApi_Product_2: TProduct = {
  id: 'NinetyNine-product-id-2',
  sellerId: 'NinetyNine',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Intensive PELVIC FLOOR MUSCLES',
  companyName: 'NinetyNine',
  logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
  shortInfoText: 'Online training and Author tours',
  fullInfoText:
    'Training sessions are recorded in your personal account and in the tg bot for 6 days of training',
  pictures: ['/assets/sellers/NinetyNine/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 2000,
};
