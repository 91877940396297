import type { TProductListItem } from '../../../product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'nethouse-product-id-1',
    sellerId: 'nethouse',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'The "Website" tariff',
    companyName: 'Nethouse',
    logoURL: '/assets/sellers/nethouse/logo-88x88.png',
    pictureURL: '/assets/sellers/nethouse/card-1-260x260.png',
    price: 500,
  },
  {
    id: 'nethouse-product-id-2',
    sellerId: 'nethouse',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'The "Store" tariff',
    companyName: 'Nethouse',
    logoURL: '/assets/sellers/nethouse/logo-88x88.png',
    pictureURL: '/assets/sellers/nethouse/card-2-260x260.png',
    price: 1050,
  },
  {
    id: 'nethouse-product-id-3',
    sellerId: 'nethouse',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'The "Professional" tariff',
    companyName: 'Nethouse',
    logoURL: '/assets/sellers/nethouse/logo-88x88.png',
    pictureURL: '/assets/sellers/nethouse/card-3-260x260.png',
    price: 3500,
  },
];
